<template>
    <empty-layout>
        <v-container v-if="user">
            <navigation-back-button></navigation-back-button>
            <div class="d-flex mb-10">
                <div class="text-h4">Upgrade</div>
            </div>
            <limited-time-offer-card v-if="showLimitedTimeOffer"></limited-time-offer-card>
            <div v-if="!hidePricing" class="d-flex align-center mb-5">
                <div class="text-subtitle-1 mr-4">Monthly</div>
                <v-switch v-model="intervalSwitch" inset></v-switch>
                <div class="d-flex ml-2">
                    <div class="text-subtitle-1">Yearly</div>
                    <v-scroll-y-reverse-transition>
                        <div v-if="intervalSwitch" class="ml-2 primary--text">(Save 15%)</div>
                    </v-scroll-y-reverse-transition>
                </div>
            </div>
            <v-row v-if="loadingPlans">
                <v-col v-for="index in 3" :key="`loader-${index}`">
                    <v-skeleton-loader
                        width="350px"
                        type="card, list-item@3"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <div v-else-if="pricePlans">
                <v-row>
                    <template v-if="!hidePricing">
                        <template v-for="plan in showingPlans">
                            <v-col :key="plan.id">
                                <price-plan-card :plan="plan" @featureDetails="handleFeatureDetails"></price-plan-card>
                            </v-col>
                        </template>
                    </template>
                    <v-col>
                        <enterprise-card v-if="!hidePricing" @featureDetails="handleFeatureDetails"></enterprise-card>
                        <div v-else>
                            <custom-plan-card @featureDetails="handleFeatureDetails"></custom-plan-card>
                        </div>
                    </v-col>
                </v-row>
                <div ref="details" class="text-h5 my-6">Features Details</div>
                <feature-details></feature-details>
            </div>
        </v-container>
    </empty-layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import PricePlanCard from './PricePlanCard.vue'
import EnterpriseCard from './EnterpriseCard.vue'
import CustomPlanCard from './CustomPlanCard.vue'
import FeatureDetails from './FeaturesTable/FeatureDetails.vue'
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
import EmptyLayout from '../../layouts/EmptyLayout.vue'
import NavigationBackButton from '../../layouts/NavigationBackButton.vue'
import LimitedTimeOfferCard from './LimitedTimeOfferCard.vue'
export default {
    components: { PricePlanCard, EnterpriseCard, FeatureDetails, EmptyLayout, NavigationBackButton, CustomPlanCard, LimitedTimeOfferCard },
    name: 'Upgrade',
    computed: {
        ...mapGetters({
            user: 'auth/user',
            pricePlans: 'billing/plans',
            showLimitedTimeOffer: 'auth/showLimitedTimeOffer'
        }),
        showingPlans(){
            return this.billingInterval === 'year'? this.yearlyPlans : this.monthlyPlans
        },
        monthlyPlans(){
            if(this.pricePlans){
                return this.pricePlans.filter(plan =>  plan.stripe_price && plan.stripe_price.recurring && plan.stripe_price.recurring.interval==='month')
            }
            return []
        },
        yearlyPlans(){
            if(this.pricePlans){
                return this.pricePlans.filter(plan =>  plan.stripe_price && plan.stripe_price.recurring && plan.stripe_price.recurring.interval==='year')
            }
            return []
        },
        intervalSwitch: {
            get(){
                return this.billingInterval === 'year'
            },
            set(val){
                if(val){
                    return this.billingInterval = 'year'
                }
                return this.billingInterval = 'month'
            }
        },
        hidePricing(){
            return process.env.VUE_APP_HIDE_PRICING === 'true'
        }
    },
    data(){
        return {
            loadingPlans: false,
            billingInterval: 'year'
        }
    },
    methods: {
        ...mapActions({
            loadPricePlans: 'billing/loadPricePlans'
        }),
        getPrice(plan){
            if(plan.stripe_price){
                return plan.stripe_price.unit_amount/100
            }

            return plan.price
        },
        goBack() {
            this.$router.go(-1)
        },
        handleFeatureDetails(){
            if(this.$refs.details){
                this.$vuetify.goTo(this.$refs.details, {
                    offset: 32,
                    duration: 100,
                    easing: 'easeInOutCubic',
                    easings: Object.keys(easings),
                })
            }
        }
    },
    async created(){
        if(!this.pricePlans){
            this.loadingPlans = true
        }
        await this.loadPricePlans()
        this.loadingPlans = false
    }
}
</script>