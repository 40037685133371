<template>
    <v-card flat class="pa-12" color="primary lighten-5">
        <v-card :dark="true" class="pa-4 card mx-auto d-flex" width="100%" max-width="700" color="secondary lighten-1">
            <div>
                <div class="text-h5 mb-4">Custom Pricing</div>
                <div class="d-flex align-end mb-1">
                    <div class="text-h4 mr-1" style="min-height: 72px;">Build your plan 🪄</div>
                </div>
                <v-btn @click="handleRequestDemoClick" class="mt-5" large color="primary" elevation="0" width="100%">
                    Book a call
                </v-btn>
                <v-btn @click="contactUsDialog=true" class="mt-5" outlined large color="primary lighten-3" elevation="0" width="100%">
                    Contact Us
                </v-btn>
            </div>
            <v-divider class="mx-6" vertical></v-divider>
            <div>
                <div class="d-flex ma-2 mt-5">
                    <v-icon color="primary lighten-3" class="mr-2">mdi-check</v-icon>
                    <div class="text-subtitle-2 mt-1">Custom response limits</div>
                </div>
                <div class="d-flex px-2 py-2" v-for="(listItem, index) in custom.shortFeaturesList" :key="`${index}-${listItem}-feature`">
                    <v-icon color="white" class="mr-2">mdi-check</v-icon>
                    <div class="text-body-2">{{listItem}}</div>
                </div>
                <div class="d-flex flex-column">
                    <v-btn text color="primary lighten-3" class="mt-4 align-self-center" @click="$emit('featureDetails')">and more...</v-btn>
                </div>
            </div>
    
            <v-dialog
                v-model="contactUsDialog"
                persistent
                max-width="600px"
            >
                <contact-us-form @close="contactUsDialog=false" @submit="handleSubmit"></contact-us-form>
            </v-dialog>
            <v-dialog v-model="bookDemoDialog" persistent max-width="600px">
                <v-app-bar elevation="0">
                    <div class="text-h6">Book a demo with us!</div>
                    <v-spacer></v-spacer>
                    <v-btn @click="bookDemoDialog=false" text>
                        Close
                    </v-btn>
                </v-app-bar>
                <div
                    class="calendly-inline-widget white"
                    style="width: 100%; height:700px"
                    id="calendly-booking"
                ></div>
            </v-dialog>
        </v-card>
    </v-card>
</template>

<script>
import {custom} from '../../utils/plansContext'
import ContactUsForm from './ContactUsForm.vue';
import {initCalendly} from '../../utils/scriptLoader'
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'CustomPlanCard',
    components: {ContactUsForm},
    data(){
        return {
            bookDemoDialog: false,
            contactUsDialog: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        custom(){
            return custom
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        handleSubmit(isSuccessful){
            if(isSuccessful){
                this.contactUsDialog = false
            }
        },
        handleRequestDemoClick(){
            this.bookDemoDialog=true
            const prefill = {
                email: this.user.email
            }
            if(this.user.name){
                prefill.name = this.user.name
            }

            if(this.user.first_name && this.user.last_name){
                prefill.name = `${this.user.first_name} ${this.user.last_name}`
            }

            setTimeout(()=>{
                const container = document.getElementById('calendly-booking');
                if(container.childElementCount === 0){
                    window.Calendly.initInlineWidget({
                        url: 'https://calendly.com/philip-voiceform/30min?hide_landing_page_details=1&hide_gdpr_banner=1',
                        parentElement: document.getElementById('calendly-booking'),
                        prefill,
                    });
                }
            }, 300)
        },
        isCalendlyEvent(e) {
            return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
        },

        handleCalendlyEvent(e){
            if(this.isCalendlyEvent(e)) {
                window.heap && window.heap.track(e.data.event, e.data.payload);
                if(e.data.event === 'calendly.event_scheduled'){
                    this.bookDemoDialog = false;

                    // Book Demo Conversion
                    window.gtag && window.gtag('event', 'conversion', {'send_to': 'AW-10927426629/nTcnCNLeosMDEMWYzdoo'});
                    this.$gtag.event('book_demo', {
                        'category': 'custom'
                    });

                    this.showSnackBar({
                        text: 'We have received your request.', 
                        color: 'success', 
                        timeout: 2000
                    })
                }
            }
        }
    },
    mounted(){
        initCalendly()
        window.addEventListener("message", this.handleCalendlyEvent)
    },
    beforeDestroy(){
        window.removeEventListener("message", this.handleCalendlyEvent)
    }
}
</script>

<style lang="scss" scoped>

 .status-container{
    position: relative;
    width: 100%;
 }
 .status{
    margin-left: auto;
    position: absolute;
    left: auto;
    right: 0;
    top: -40px;
 }
 .card{
     color: white;
 }
</style>

