<template>
    <v-card :class="['pa-4 mx-auto d-flex flex-column', isActivePlan?'active-card-border':'card-border']" max-width="350" outlined width="100%" height="100%" :color="isActivePlan?'primary lighten-5': 'white'" border>
        
            <div v-if="isActivePlan" class="status-container">
                <v-chip
                    class="status ma-2"
                    color="primary"
                >
                    Current Plan
                </v-chip>
            </div>
        
        <div class="text-h5 mb-4">{{plan.name}}</div>
        <div class="d-flex align-end mb-1">
            <div class="text-h4 mr-1">{{price}} USD</div>
            <div class="ml-1 mb-2">/mo</div>
        </div>
        <div class="text-body-2">Billed {{interval === 'year'? 'yearly' :'monthly'}}</div>
        <!-- Killing free trial for now -->
        <!-- <div v-if="false">
            <v-btn @click="showStartFreeTrialOption"  class="mt-5" large color="primary" elevation="0" width="100%">
                Start Free Trial
            </v-btn>
            <router-link :to="{name: 'dashboard.checkout', params: {planId: plan.id}}">
                <v-btn class="mt-2" text color="primary" elevation="0" width="100%">
                    {{buttonText}}
                </v-btn>
            </router-link>
        </div> -->
        <router-link :to="{name: 'dashboard.checkout', params: {planId: plan.id}}">
            <v-btn class="mt-5" large color="primary" elevation="0" width="100%">
                {{buttonText}}
            </v-btn>
        </router-link>
        <div class="d-flex ma-2 mt-5">
            <v-icon color="primary" class="mr-2">mdi-check</v-icon>
            <div class="text-subtitle-2 mt-1">{{responsesLimit}}</div>
        </div>
        <div class="d-flex pa-2" v-for="(listItem, index) in details.shortFeaturesList" :key="`${index}-${listItem}-feature`">
            <v-icon class="mr-2">mdi-check</v-icon>
            <div class="text-body-2">{{listItem}}</div>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex flex-column">
            <v-btn text color="primary" class="mt-4 align-self-center" @click="$emit('featureDetails')">and more...</v-btn>
        </div>
        <v-dialog
            v-model="startTrialDialogue"
            max-width="600px"
            persistent
        >
            <free-trial-confirmation @close="startTrialDialogue=false"></free-trial-confirmation>
        </v-dialog>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import {getContextByPriceId} from '../../utils/plansContext'
import FreeTrialConfirmation from './FreeTrialConfirmation.vue'
export default {
    components: { FreeTrialConfirmation },
    name: 'PricePlanCard',
    props: {
        plan: {
            required: true
        },
    },
    data(){
        return {
            startTrialDialogue: false
        }
    },
    computed: {
        ...mapGetters({
            subscription: 'billing/subscription',
            user: 'auth/user'
        }),
        isActivePlan(){
            return !!this.subscription && this.subscription.plan && (this.plan.stripe_price.id === this.subscription.plan.id)
        },
        showStartFreeTrialOption(){
            return !this.isActivePlan && this.user && !this.user.trial_ends_at 
        },
        price(){
            let price = 0
            if(this.plan.stripe_price){
                price = this.plan.stripe_price.unit_amount/100
            }else{
                price = this.plan.price
            }
            if(this.interval === 'year'){
                price = price/12
            }

            return `${new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(price)}`
        },
        interval(){
            if(this.plan.stripe_price){
                return this.plan.stripe_price.recurring.interval
            }

            return this.plan.interval
        },
        buttonText(){
            if(this.isActivePlan){
                return 'Change Plan'
            }
            return `Get ${this.plan.name} ${this.interval === 'year'? 'yearly' :'monthly'}`
        },
        details(){
            return getContextByPriceId(this.plan.stripe_id)
        },
        responsesLimit(){
            return `${this.plan.restrictions.submission_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} responses/mo included`
        }
    }
}
</script>

<style lang="scss" scoped>
 .status-container{
    position: relative;
    width: 100%;
 }
 .status{
    margin-left: auto;
    position: absolute;
    left: auto;
    right: 0;
    top: -40px;
 }
 .card-border{
    border: thin solid var(--v-primary-lighten4) !important;
 }
 .active-card-border{
    border: thin solid var(--v-primary) !important;
 }
</style>