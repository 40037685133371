<template>
    <v-data-table
        :headers="headers"
        hide-default-footer
        :items-per-page="-1"
        :items="features"
        class="elevation-0"
        disable-sort
    >
        <template v-slot:item.name="{ item }">
            <div style="min-width: 250px" class="text-subtitle-2">{{item.name}}</div>
        </template>
        <template v-slot:item.free="{ item }">
            <feature-table-cell :value="item.free"></feature-table-cell>
        </template>
        <!-- <template v-slot:item.essentials="{ item }">
            <feature-table-cell :value="item.essentials"></feature-table-cell>
        </template>
        <template v-slot:item.pro="{ item }">
            <feature-table-cell :value="item.pro"></feature-table-cell>
        </template> -->
        <template v-slot:item.enterprise="{ item }">
            <feature-table-cell :value="item.enterprise"></feature-table-cell>
        </template>
    </v-data-table>
</template>

<script>
import FeatureTableCell from './FeatureTableCell.vue'
  export default {
    components: { FeatureTableCell },
    data () {
      return {
        headers: [
          {
            text: 'Features',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Free', value: 'free' },
        //   { text: 'Essentials', value: 'essentials' },
        //   { text: 'Pro', value: 'pro' },
        //  { text: 'Enterprise', value: 'enterprise' },
        { text: 'Custom Pricing', value: 'enterprise' },
        ],
        features: [
            {
                name: '# of responses per month',
                free: 10,
                // essentials: 25,
                // pro: 100,
                enterprise: 'Custom',
            },
            {
                name: '# of seats',
                free: '1 seat',
                // essentials: '3 seats',
                // pro: '5 seats',
                enterprise: 'Custom',
            },
            {
                name: 'Unlimited Voiceforms',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Unlimited Questions',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Transcription',
                free: false,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Transcription Languages',
                free: false,
                // essentials: true,
                // pro: true,
                enterprise: 'All Languages + adding new languages',
            },
            {
                name: 'Question Types',
                free: 'All question types except File Upload',
                // essentials: true,
                // pro: true,
                enterprise: 'All question types available',
            },
            {
                name: 'Email Notifications',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Base Analytics',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Audio Analytics',
                free: false,
                // essentials: 'Audio Analysis (Sentiment Extraction, Keywords Extraction, Keywords Sentiment, Top Keywords filter)',
                // pro: 'Audio Analysis (Sentiment Extraction, Keywords Extraction, Keywords Sentiment, Top Keywords filter)',
                enterprise: 'Audio Analysis (Sentiment Extraction, Keywords Extraction, Keywords Sentiment, Top Keywords filter)',
            },
            {
                name: 'Embedding',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Custom Design',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Redirect on submit',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Data Export',
                free: false,
                // essentials: 'XLSX, CSV',
                // pro: 'XLSX, CSV',
                enterprise: 'XLSX, CSV',
            },
            {
                name: 'Audio Export',
                free: false,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Custom Labels',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Duplicate Voiceforms',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Maximum Length per voice response',
                free: 'Up to 2mins 30sec',
                // essentials: 'Up to 5mins',
                // pro: 'Up to 10mins',
                enterprise: 'Custom Length',
            },
            {
                name: 'Share Voiceforms by CSV',
                free: false,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Schedule Voiceform End Date',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Remove Voiceform Branding',
                free: false,
                // essentials: false,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Image Attachment',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Video Attachment',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'Webhooks & Integrations',
                free: true,
                // essentials: true,
                // pro: true,
                enterprise: true,
            },
            {
                name: 'SSO Authentication',
                free: false,
                // essentials: false,
                // pro: false,
                enterprise: true,
            },
            {
                name: 'Dedicated Account Manager',
                free: false,
                // essentials: false,
                // pro: false,
                enterprise: true,
            },
            {
                name: 'Onboarding + Training',
                free: false,
                // essentials: false,
                // pro: false,
                enterprise: true,
            },
            {
                name: 'Custom Development',
                free: false,
                // essentials: false,
                // pro: false,
                enterprise: true,
            },
            {
                name: 'Access to Voiceform API',
                free: false,
                // essentials: false,
                // pro: false,
                enterprise: true,
            },
            {
                name: 'Real Time Partial Responses',
                free: false,
                // essentials: false,
                // pro: false,
                enterprise: true,
            }
        ],
      }
    },
  }
</script>