<template>
    <div style="min-width: 300px" class="py-4">
        <v-icon v-if="isBooleanValue" :color="value?'primary':'grey'">{{value?'mdi-check-circle':'mdi-close-circle'}}</v-icon>
        <div class="text-body-2" v-else>{{value}}</div>
    </div>
</template>

<script>
export default {
    props: ['value'],
    computed: {
        isBooleanValue(){
            if(typeof this.value === 'boolean'){
                return true
            }
            return false
        }
    }
}
</script>