<template>
    <v-card flat class="pa-12">
        <v-card elevation="3" :dark="true" class="pa-4 card mx-auto d-flex flex-column rounded-lg" width="100%">
            <div class="relative">
                <v-chip style="position: absolute; top: -10px;" color="deep-orange">
                    Limited Time Offer
                </v-chip>
                <div class="d-flex text-body-2 align-center my-6">
                    Monthly
                        <v-switch small class="ma-0 mx-4" hide-details v-model="yearly"></v-switch>
                    Yearly
                </div>
            </div>
            <v-row no-gutters>
                <v-col class="pr-4" sm="6">
                    <div class="text-h4 text-bold mb-4">${{price}} per month for 100 form submissions!</div>
                    <div class="d-flex align-end mb-1">
                        <div class="text-body-1 mr-1" style="min-height: 72px;">Act fast! Unprecedented prices for a limited time. Your best deal ever awaits. Hurry, time is ticking!</div>
                    </div>

                    <div v-if="distance>0" class="my-4">
                        <div class="text-h4" style="min-height: 72px;">{{ hours }}h {{ minutes }}m {{ seconds }}s</div>
                    </div>
                    <v-btn :to="{name: 'dashboard.checkout', params: {planId: planId}}" class="my-3 primary--text" height="60" @click="contactUsDialog=true" large color="primary lighten-5" elevation="0" width="100%">
                        <span class="text-subtitle-1">Subscribe Now </span>
                    </v-btn>
                </v-col>
                <v-col class="flex-grow-1 d-flex" sm="6">
                    <div class="d-flex flex-grow-1">
                        <v-divider vertical></v-divider>
                        <div class="pl-4 d-flex flex-column">
                            <div class="d-flex ma-2 mt-5">
                                <v-icon color="primary lighten-3" class="mr-2">mdi-check</v-icon>
                                <div class="text-subtitle-2 mt-1">100 form submissions per month</div>
                            </div>
                            <div class="d-flex px-2 py-2" v-for="(listItem, index) in limitedTimeOffer.shortFeaturesList" :key="`${index}-${listItem}-feature`">
                                <v-icon color="white" class="mr-2">mdi-check</v-icon>
                                <div class="text-body-2">{{listItem}}</div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-card>
</template>

<script>
import {limitedTimeOffer} from '../../utils/plansContext'
import ContactUsForm from './ContactUsForm.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'CustomPlanCard',
    components: {ContactUsForm},
    data(){
        return {
            bookDemoDialog: false,
            contactUsDialog: false,
            countDownDate: null,
            distance: null,
            yearly: true,
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        limitedTimeOffer(){
            return limitedTimeOffer
        },
        hours() {
            return this.pad(Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        },
        minutes() {
            return this.pad(Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60)));
        },
        seconds() {
            return this.pad(Math.floor((this.distance % (1000 * 60)) / 1000));
        },
        price(){
            return this.yearly ? 20.83 : 25
        },
        planId(){
            return this.yearly ? limitedTimeOffer.yearly : limitedTimeOffer.monthly
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        pad(value) {
            return (value < 10) ? '0' + value : value;
        },
        calculateCountDown() {
            var now = new Date().getTime();
            this.distance = this.countDownDate - now;
        }
    },
    mounted(){
        this.countDownDate = new Date(this.user.limited_time_offer).getTime(); // Set countdown to 16 hours from now
        this.calculateCountDown(); // Initial calculation
        this.interval = setInterval(this.calculateCountDown, 1000); // Update every second
    },
    beforeDestroy(){
        clearInterval(this.interval); // Clear interval to avoid memory leaks
    }
}
</script>

<style lang="scss" scoped>

 .status-container{
    position: relative;
    width: 100%;
 }
 .status{
    margin-left: auto;
    position: absolute;
    left: auto;
    right: 0;
    top: -40px;
 }
 .card{
     color: white;
     background: linear-gradient(180deg,#003b93 0%, #2341E0 100%);
 }
</style>

