<template>
    <v-card class="pa-6 card mx-auto">
        <div class="text-h5 ma-4">Get started with a 15 day free trial! 🎉</div>
        <div class="body-1 ma-4">{{freeTrial.description}}</div>
        <div v-for="(listItem, index) in freeTrial.shortFeaturesList" :key="`${index}-${listItem}-feature`" class="d-flex ma-4">
            <v-icon color="primary" class="mr-2">mdi-check</v-icon>
            <div class="text-body-2">{{listItem}}</div>
        </div>
        <div class="ma-4">
            <div class="text-body-2">...and more</div>
        </div>
        <v-card-actions class="py-6">
            <v-btn elevation="0" :loading="loading" :disabled="loading" large color="primary" @click="handleStartTrialClick">
                Start Your 15-Day Free Trial
            </v-btn>
            <v-btn :disabled="loading" text large @click="$emit('close')">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import { freeTrial } from '../../utils/plansContext'
export default {
    data(){
        return {
            freeTrial,
            loading: false
        }
    },
    methods:{
        ...mapActions({
            startFreeTrial: 'billing/startFreeTrial',
        }),
        ...mapMutations(['showSnackBar']),
        async handleStartTrialClick(){
            try {
                this.loading = true
                const res = await this.startFreeTrial()
                this.loading = false
                if(res.data){
                    if(res.data.free_trial_updated){
                        // Free Trial conversion 
                        window.gtag && window.gtag('event', 'conversion', {'send_to': 'AW-10927426629/y0cpCPKpn8MDEMWYzdoo'});

                        this.$gtag.event('free_trial_start', {
                            'event_label': 'free_trial'
                        });
                        
                        this.showSnackBar({
                            text: '15 days Free Trial Started.', 
                            color: 'success', 
                            timeout: 2000
                        })
                        return this.$router.push({name: 'dashboard.billing'})
                    }

                    if(res.data.error){
                        this.showSnackBar({
                            text: res.data.error, 
                            color: 'error', 
                            timeout: 2000
                        })
                    }
                }
            } catch (error) {
                this.loading = false
                this.showSnackBar({
                    text: "Something went wrong. Can't start the Free Trial.", 
                    color: 'error', 
                    timeout: 3000
                })
                console.error('handleStartTrialClick',error);
            }
        }
    }
}
</script>